export default {
  public: {
    queryBtn: '查询',
    submit: '提交',
    goBack: '返回',
    cancel: '取消',
    keywordQuery: '请输入关键字',
    more: '更多',
    noData: '暂无数据',
    noMore: '没有更多数据了',
    fastComplaint: '快速投诉',
    time: '时间',
    currentLocation: '当前位置',
    element: '元', // 注: 钱的那个元
    prevPage: '上一页',
    nextPage: '下一页',
	voiceInput: '长按语音录入',
	voiceEnd: '录入结束'
  },

  // 上传组件
  uploadStatus: {
    uploading: '上传中',
    failed: '上传失败',
    done: '上传完成',
  },

  tousu: {
    step1: '第一步：填写反馈内容',
    step2: '第二步：输入手机号获取验证码',
    step3: '第三步：输入刚获取到的验证码',
    feedbackContent: '填写反馈内容',
    inputPhone: '输入手机号',
    inputCode: '输入验证码',
    getCode: '获取验证码',
    retrieveCode: '重新获取验证码'
  },

  // 民生资金
  index: {
    index: '首页',
    personal: '个人查询',
      fundReleaseQuery: '个人资金发放查询',
      fundReleaseQuerySearch: '请输入身份证号码或姓名',
      uploadIDPhotos: '点击下方图标，拍摄身份证查询资金明细',

    huimin: '惠民查询',
      departQuery: '部门查询',
      regionQuery: '区域查询',
      classCapital: '类资金',

    threeCapital: '三资查询',
      capital: '资金',
      capitalItem: {
        inCome: '收入',
        expend: '支出',
        category: '资金类别',
        amount: '资金金额',
        time: '资金时间',
        supply: '其他补充',
      },
        
      assets: '资产',
      assetsQuery: '资产查询',
      assetsItem: {
        type: '型号',
        quantity: '数量',
        status: '状态',
        money: '金额',
        purchaseTime: '购置时间',
      },
      
      resources: '资源',
      resourcesQuery: '资源查询',
      resourcesItem: {
        type: '类型',
        operateMode: '经营方式',
        devSituation: '开发情况',
      },

      villageAffairs: '村(居)务',
      villageAffairsQuery: '村务查询',

    projectDynamics: '项目动态',
    projectQuery: '项目查询',
    policyInquiry: '政策查询',
    policyDeatil: '政策详情',
    superviseDetail: '监督清单详情',
    policyInquiryItem: {
      allPolicy: '全部政策',
      centralPolicy: '中央政策',
      provincialPolicy: '省级政策',
      municipalPolicy: '市级政策',
      countyPolicy: '县级政策',
    },

    complaintReply: '投诉回复',
    complaintQuery: '投诉查询',
    complaint: {
      detailInfo: '详细信息',
      letterTitle: '信件标题',
      letterType: '信件类型',
      handleState: '处理状态',
      letterTime: '信件时间',
      replyMessage: '回复信息',
      replyUnit: '回复单位',
      replyContent: '回复内容',
      replyTime: '回复日期',

      evaluateResult: '评价结果',
      processingLog: '处理日志',
      evaluate: '评价',
      isSatisfied: '是否满意',
      satisfied: '满意',
      dissatisfied: '不满意',

      entryCode: '请输入验证码',
      entryPhone: '请输入手机号',
      getCode: '获取验证码',
      querying: '正在查询中...'
    },

    // 补贴
    subsidy: {
      personFundsDetail: '个人资金明细',
      query: '补贴查询',
      grantObj: '发放对象',
      auditProcess: '审核流程',
      grantStandard: '发放标准',
      grantArea: '发放区域',
      grantMoney: '发放金额',
      grantTime: '发放时间',
      grantAddress: '发放地址',
      grantStatus: '发放状态',
      noData: '经查询，无资金明细数据',
      personDetail: '个人明细',
      consultUnit: '咨询单位',
      consultPhone: '咨询电话',
      policyStatute: '政策法规',
    },

    personInfo: {
      info: '个人基础信息',
      name: '姓名',
      idCard: '身份证号',
      IPass: '一卡通号',
    }
  },

  // 随手拍
  casually: {
    feedback: '意见反馈',
    feedbackContent: '内容',
    problemAndFeedback: '问题和反馈',
    reflectContent: '请具体描述所要反映的情况',
    pic: '图片',
    picProblem: '（提供问题截图）',
    video: '视频',
    videoProblem: '（提供问题视频）',
    videoTips: '单个视频文件大小不得超过50M',
  },
  
  // 小微权利
  supervise: {
    tabList: {
      depart: '部门监督',
      rural: '乡村监督',
      super: '权力监督',
    },

    level2TabList: {
      interest: '办事指南',
      object: '对象监督',
      rectify: '整改监督',
      project: '项目公开',
      involvingRights: '涉权监督',
    },
    // 部门监督
    departGroup: {
      charge: "主要负责人",
      teamMembers: "班子成员",
      postKeyPerson: "重点岗位工作人员",
      otherCadres: "其他领导干部",
      windowStaff: "窗口工作人员",
    },
    // 乡村监督
    RuralGroup: {
      leadCadre: '领导干部',
      other: '领导干部',
    },
    // 整改监督
    rectify: {
      letterType: '信件类型',
      state: '处理状态',
      createTime: '信件时间',
      name: '回复单位',
      backcontent: '回复内容',
      updateTime: '回复时间',
    },
    // 项目公开
    project: {
      titleList: {
        planyear: '计划年份',
        area: '地区',
        pno: '批复文号',
        porno: '项目编号',
        company: '中标单位',
        type: '项目类型',
        starttime: '开工时间',
        endtime: '竣工时间',
        price: '竣工决算价',
        remark: '备注',
      }
    },
    // 办事指南详情
    interestDetail: {
      sourceTitle: '办事指南详情',
      implementation: '受理单位',
      myend: '承诺办结时限',
      faend: '法定办结时限',
      weekdays: '个工作日',     // 示例: 1个工作日
      ismoney: '收费标准',
      free: '免费',
      charge: '收费',
      accept: '办理条件',
      process: '办理流程',
    },
    // 涉权监督详情
    involvingRightsDetail: {
      sourceTitle: '涉权监督详情',
      personInfo: '本人信息',
      name: '姓名',
      post: '职位',
      relativesInfo: '亲属信息',
      relationship: '亲属关系',
      complaints: '投诉意见',
    },
    indexSuperDetail: {
      sourceTitle: '权力监督详情',
    }
  },

  // 监督
  travel: {
    tabList: {
      policy: '政策',
      interest: '办事指南',
      huiqi: '惠企资金',
      enterprise: '监督投诉',
    },
    // 政策
    policy: {
      policyTitle: '政策查询'
    },
    // 监督投诉
    enterprise: {},
    // 惠企资金
    huiqi: {
      enterpriseName: '企业名称',
      affirmType: '认定类型',
      affirmUnit: '认定单位',
      rewardAmount: '奖励金额',
      time: '时间',
    }
  },

  footer: {
    todayVisits: '今日访问量',
    cumulativeVisits: '累计访问量',
    producer: '监制单位',
    contactAddress: '联系地址',
    producerName: '中共新疆维吾尔自治区纪律检查委员会',
    contactAddressName: '新疆乌鲁木齐市东风路10号自治区纪委',
  }
}
