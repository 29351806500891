import Vue from "vue";
import Router from "vue-router";

// import Index from "./pages/index";
const Index = () => import("@/pages/index");
// import Layout from "./pages/layout";
const Layout = () => import("@/pages/layout");
// import Detail from "./pages/detail";
const Detail = () => import("@/pages/detail");
// import xmDetail from "./pages/xmdetail";
const xmDetail = () => import("@/pages/xmdetail");
// import List from "./pages/list";
const List = () => import("@/pages/list");
// import xmList from "./pages/xmlist";
const xmList = () => import("@/pages/xmlist");
// import FundDetail from "./pages/funddetail";
const FundDetail = () => import("@/pages/funddetail");
// import tousuDetail from "./pages/tousudetail";
const tousuDetail = () => import("@/pages/tousudetail");
// import tousu from "./pages/tousu";
const tousu = () => import("@/pages/tousu");
// import FundList from "./pages/fundlist";
const FundList = () => import("@/pages/fundlist");

const fundPersonList = () => import("@/pages/fundPersonList");

// import LayoutTousuSearch from "./pages/layout_tousu_search";
const LayoutTousuSearch = () => import("@/pages/layout_tousu_search");
// import TousuSearch from "./pages/toususearch";
const TousuSearch = () => import("@/pages/toususearch");
// import TousuSearchDetail from "./pages/toususearch_detail";
const TousuSearchDetail = () => import("@/pages/toususearch_detail");
// import IncomeList from "./pages/IncomeList";
const IncomeList = () => import("@/pages/IncomeList");
// import AssetsList from "./pages/AssetsList";
const AssetsList = () => import("@/pages/AssetsList");
// import AffairsList from "./pages/AffairsList";
const AffairsList = () => import("@/pages/AffairsList");
// import ResourceList from "./pages/ResourceList";
const ResourceList = () => import("@/pages/ResourceList");

// 小微权利
const LayoutSupervise = () => import("@/pages/layout_supervise");
// 监督
const superviseIndex = () => import("@/pages/supervise/index");
// 办事指南详情
const interestDetail = () => import("@/pages/supervise/interest_detail");
// 权利监督详情
const indexSuperDetail = () =>
  import("@/pages/supervise/indexChild/indexSuperDetail");
// 涉权监督
const indexInvolvingRightsDetail = () =>
  import("@/pages/supervise/indexChild/indexInvolvingRightsDetail");

const LayoutTravel = () => import("@/pages/layout_travel");
// 企业发展监督
const travelIndex = () => import("@/pages/travel/index");
// 旅游环境监督
const travelIndex1 = () => import("@/pages/travel/index1");

const indexCapital = () => import("@/pages/indexCapital");

// 随手拍
const LayoutCasually = () => import('@/pages/layout_casually');
const CasuallyIndex = () => import('@/pages/casually/index');
// 系统升级提示
const systemTip = () => import('@/pages/tips');
const originalPush = Router.prototype.push;

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(Router);

const routes = [
	{
	  path: "/systemTip",
	  name: "systemTip",
	  component: systemTip,
	},
  // 随手拍 Clap casually
  {
    path: "/layout_casually",
    name: "layout_casually",
    component: LayoutCasually,
    children: [
      {
        path: "/casually_index",
        name: "casually_index",
        component: CasuallyIndex,
        meta: {
          keepAlive: true,
        }
      }
    ]
  },
  // 旅游
  {
    path: "/layout_travel",
    name: "layout_travel",
    component: LayoutTravel,
    children: [
      {
        path: "/business_development_index",
        name: "business_development_index",
        component: travelIndex,
        meta: {
          keepAlive: false,
        },
      },
      {
        path: "/tourism_environment_index",
        name: "tourism_environment_index",
        component: travelIndex1,
        meta: {
          keepAlive: false,
        },
      },
      {
        path: "/index_capital",
        name: "index_capital",
        component: indexCapital,
        meta: {
          keepAlive: true,
        },
      },
	  {
	    // path: "/zcdetail/:id",
	    path: "/zcdetail",
	    name: "zcdetail",
	    component: Detail,
	  },
	  {
	    path: "/interest_detail1",
	    name: "interest_detail1",
	    component: interestDetail,
	    meta: {
	      keepAlive: false,
	    },
	  },
    ],
  },
  // 小微权利
  {
    path: "/layout_supervise",
    name: "layout_supervise",
    component: LayoutSupervise,
    children: [
      {
        path: "/supervise_index",
        name: "supervise_index",
        component: superviseIndex,
        meta: {
          keepAlive: true,
        },
      },
      {
        path: "/interest_detail",
        name: "interest_detail",
        component: interestDetail,
        meta: {
          keepAlive: false,
        },
      },
      {
        path: "/indexChild/index_super_detail",
        name: "index_super_detail",
        component: indexSuperDetail,
        meta: {
          keepAlive: false,
        },
      },
      {
        path: "/indexChild/index_involving_rights_detal",
        name: "index_involving_rights_detal",
        component: indexInvolvingRightsDetail,
        meta: {
          keepAlive: false,
        },
      },
    ],
  },
  // 投诉查询
  {
    path: "/tousu_search",
    name: "tousu_search",
    component: LayoutTousuSearch,
    meta: {
      keepAlive: true,
    },
    children: [
      {
        path: "",
        component: TousuSearch,
      },
      {
        path: "/tousu_search_detail/:id",
        name: "tousu_search_detail",
        component: TousuSearchDetail,
      },
    ],
  },
  {
    path: "/",
    name: "index",
    component: Layout,
    meta: {
      keepAlive: true, // 需要被缓存
    },
    children: [
      {
        path: "",
        component: Index,
      },
      {
        path: "/xmlist/",
        name: "xmlist",
        component: xmList,
      },
      {
        // path: "/xmdetail/:id",
        path: "/xmdetail",
        name: "xmdetail",
        component: xmDetail,
      },
      {
        path: "/fundDetail/:idcard?/:name?",
        // path: "/fundDetail/:idcard",
        name: "funddetail",
        component: FundDetail,
      },
      {
        path: "/fundList",
        name: "fundlist",
        component: FundList,
      },
      {
        path: "/fundPersonList/:idcard?",
        name: "fundPersonList",
        component: fundPersonList,
      },
      {
        // path: "/zcdetail/:id",
        path: "/zcdetail",
        name: "zcdetail",
        component: Detail,
      },
      {
        path: "/tousu/",
        name: "tousu",
        component: tousu,
      },
      {
        // path: "/tousudetail/:id",
        path: "/tousudetail",
        name: "tousudetail",
        component: tousuDetail,
      },
      {
        path: "/zclist/",
        name: "zclist",
        component: List,
      },
      {
        // path: "/income/:id/:code",
        path: "/income",
        name: "income",
        component: IncomeList,
      },
      {
        // path: "/assets/:id/:code",
        path: "/assets",
        name: "assets",
        component: AssetsList,
      },
      {
        // path: "/affairs/:id/:code",
        path: "/affairs",
        name: "affairs",
        component: AffairsList,
      },
      {
        // path: "/resource/:id/:code",
        path: "/resource",
        name: "resource",
        component: ResourceList,
      },
      {
        path: "/:id?",
        component: Index,
      },
    ],
  },
];
// const router = new Router({
//     routes,
// });
// router.beforeEach((to, from, next) => {
//     console.log(to); //即将去的路由
//     console.log(from);//当前要离开的路由
// 	if (to.path == "/systemTip") {
// 		next();
// 	} else {
// 		next({path: '/systemTip'});
// 	}
// })
export default new Router({
  // mode: 'history',
  routes,
});
// export default router;

