export default {
  public: {
    // 查询
    queryBtn: ' سۈرۈشتۈرۈش',
    submit: 'يوللاش',
    // 返回
    goBack: 'قايتىش',
    cancel: 'ئۆچۈرۈش',
    // 请输入关键字
    keywordQuery: 'ئاچقۇچلۇق سۆزنى كىرگۈزۈڭ ',
    // 更多
    more: 'كۆپ تېخىمۇ ',
    // 暂无数据
    noData: 'يوق مەزمون',
    // 没有更多数据了
    noMore: 'يوق مەزمون ئارتۇق',
    // 快速投诉
    fastComplaint: 'قىلىش ئەرز',
    // 时间
    time: 'ۋاقىت',
    currentLocation: 'ئورنى ھازىرقى',
    element: 'يۈەن',
    prevPage: 'بەت ئالدىنقى',
    nextPage: 'بەت كىينكى',
  },
  
  // 上传组件
  uploadStatus: {
    uploading: 'يوللىنۋاتىدۇ',
    failed: 'بولدى مەغلۇپ يوللىنىش',
    done: 'بولدى مۇۋاپپىقىيەتلىك يوللىنىش',
  },

  tousu: {
    step1: 'تولدۇرۇڭ مەزمۇنىنى ئىنكاس',
    step2: 'كىرگۈزۈڭ نومۇرىنى تېلېفون',
    step3: 'كىرگۈزۈڭ كودىنى دەلىللەش تاپشۇرۋالغان',
    feedbackContent: 'تولدۇرۇڭ مەزمۇنىنى ئىنكاس',
    inputPhone: 'كىرگۈزۈڭ نومۇرىنى تېلېفۇن',
    inputCode: 'كىرگۈزۈڭ كودىنى دەلىللەش',
    getCode: 'يوللاش كودى دەلىللەش',
    retrieveCode: 'يوللاش قايتا كودنى دەلىللەش'
  },

  // 民生资金
  index: {
    index: '首页ug',
    // 个人查询
    personal: 'شەخسىي سۈرۈشتۈرۈش',
      // 个人资金发放查询
      fundReleaseQuery: 'شەخسىي مەبلەغ تارقىتىلىشىنى سۈرۈشتۈرۈش ',
      // 请输入身份证号码或姓名
      fundReleaseQuerySearch: 'كىرگۈزۈڭ فامىلىڭىزنى-ئىسىم ياكى نومۇرى كىملىك',
      // 点击下方图标，拍摄身份证查询资金明细
      uploadIDPhotos: 'تەكشۈرۈڭ تەپسىلاتىنى مەبلىغى تارتىپ رەسىمگە كىملىك ،چېكىپ سىنبەلگىنى ئاستىدىكى',

    // 惠民查询
    huimin: 'ئەلگە نەپ سىياسىتىنى تەكشۈرۈش ',
      // 部门查询
      departQuery: 'تارماقلار سۈرۈشتۈرۈش ',
      // 区域查询
      regionQuery: 'رايونلار بويىچە سۈرۈشتۈرۈش ',
      // 类资金
      classCapital: 'تۈرى مەبلەغ',

    // 三资查询
    threeCapital: 'ئۈچ خىل مەبلەغ سۈرۈشتۈرۈش ',
      // 资金
      capital: 'مەبلەغ',
      capitalItem: {
        // 收入
        inCome: 'كىرىم',
        // 支出
        expend: 'چىقىم',
        // 资金类别
        category: 'تۈرى مەبلەغ',
        // 资金金额
        amount: 'سوممىسى مەبلەغ',
        // 资金时间
        time: 'ۋاقتى مەبلەغ',
        // 其他补充
        supply: 'تولۇقلاش',
      },
      // 资产
      assets: 'مۈلۈك',
      assetsQuery: 'تەكشۈرۈش مۈلۈك',
      assetsItem: {
        // 型号
        type: 'تىپى',
        // 数量
        quantity: 'سانى',
        // 状态
        status: 'ھالىتى',
        // 金额
        money: 'سوممىسى',
        // 购置时间
        purchaseTime: 'ۋاقىت سىتىۋىلىنغان',
      },
      // 资源
      resources: 'مەنبە',
      resourcesQuery: 'تەكشۈرۈش مەنبەسىنى',
      resourcesItem: {
        // 类型
        type: 'تۈرى',
        // 经营方式
        operateMode: 'ئۇسۇلى تىجارەت',
        // 开发情况
        devSituation: 'ئەھۋالى ئېچىش',
      },

      // 村(居)务
      villageAffairs: 'ئىشلىرى (تۇرالغۇ) كەنت',
      villageAffairsQuery: 'سۈرۈشتۈرۈش ئىشلىرىنى كەنت',

    // 项目动态
    projectDynamics: 'تۈر ھالىتى ',
    // 项目查询
    projectQuery: 'سۈرۈشتۈرۈش تۈر',
    // 政策查询
    policyInquiry: 'سىياسەت سۈرۈشتۈرۈش ',
    policyDeatil: 'تەپسىلاتى سىياسەت',
    superviseDetail: 'تەپسىلاتى تىزىملىكىنىڭ نازارەتنازارەت',
    policyInquiryItem: {
      allPolicy: '全部政策ug',  // 全部政策
      centralPolicy: '中央政策ug',  // 中央政策
      provincialPolicy: '省级政策ug', // 省级政策
      municipalPolicy: '市级政策ug',  // 市级政策
      countyPolicy: '县级政策ug', // 县级政策
    },
    // 投诉回复
    complaintReply: 'ئەرزگە جاۋاب قايتۇرۇش ',
    complaint: {
      detailInfo: 'ئۇچۇر تەپسىلىي',
      letterTitle:  'ماۋزۇسى خەت-چەك',
      letterType:  'تىپى خەت-چەك',
      handleState:  'ھالىتى بىر تەرەپ قىلىش',
      letterTime:  'ۋاقتى خەت-چەك',
      replyMessage: 'قايتۇرۇش ئۇچۇر',
      replyUnit: 'جاۋاپ ئىدارىگە',
      replyContent: 'مەزمونى جاۋاپ',
      replyTime: 'ۋاقتى جاۋاپ',
      evaluateResult: 'نەتىجىسى باھالاش',
      processingLog: 'قىلىش كۈندىلىك خاتىرىنى بىر تەرەپ',
      
      evaluate: 'باھالاش',
      isSatisfied: 'ئەمەسمۇ رازىمۇ',
      satisfied: 'رازى',
      dissatisfied: 'نارازى',

      entryCode: 'كىرگۈزۈڭ دەلىللەش نومۇرىنى',
      entryPhone: 'كىرگۈزۈڭ تېلىفون نومۇرىنى',
      getCode: 'ئېرىشىش دەلىللەش كودىغا',
      querying: 'تەكشۈرۋاتىدۇ',
    },
    

    // 补贴
    subsidy: {
      personFundsDetail: 'تەپسىلاتى شەخسىي مەبلەغ',
      query: 'سۈرۈشتۈرۈش ياردەم پۇلىنى',
      grantObj: 'ئوبيېكتى تارقىتىش',
      auditProcess: 'تەرتىپى تەكشۈرۈش',
      grantStandard: 'ئۆلچىمى تارقىتىش',
      grantArea: 'رايونى تارقىتىش',
      grantMoney: 'سومما تارقىتىلغان',
      grantTime: 'ۋاقتى تارقىتىش',
      grantAddress: 'ئادرېسى تارقىتىش',
      grantStatus: 'ھالىتى تارقىتىش',
      noData: 'يوق مەلۇماتى سانلىق تەپسىلىي مەبلەغ',
      personDetail: 'تەپسىلىي شەخسىي',
      consultUnit: 'ئورۇن مەسلىھەت بەرگۈچى',
      consultPhone: 'تېلېفونى مەسلىھەت سوراش',
      policyStatute: 'نىزام-سىياسەت، قانۇن',
    },

    personInfo: {
      info: 'ئۇچۇر شەخسىي ئاساسىي',
      name: 'فامىلە-ئىسىم',
      idCard: 'نومۇرى كىملىك',
      IPass: 'نومۇرى ھەممىباب كارتا',
    }
  },

  // 随手拍
  casually: {
    feedback: 'قايتۇرۇش پىكىر',
    feedbackContent: 'مەزمۇن',
    problemAndFeedback: 'ئىنكاس مەسىلە ۋە قايتما',
    reflectContent: 'ئىنكاس قىلماقچى بولغان ئەھۋالنى كونكرېت بايان قىلىڭ',
    pic: 'رەسىم',
    picProblem: 'مەسىلە كەسمە رەسىمى بىلەن تەمىنلەڭ',
    video: 'ۋىدېئو',
    videoProblem: 'تەمىنلەڭ مەسىلە ۋىدىئوسى بىلەن',
    videoTips: 'دىن ئېشىپ كەتمەسلىكى كېرەك يەككە سىن ھۆججىتىنىڭ چوڭ-كىچىكلىكى 50',
  },

  // 小微权利
  supervise: {
    tabList: {
      depart: 'نازارەتچىلىكى تارماقلار',
      rural: 'نازارەتچىلىكى يېزا',
      super: 'نازارەتچىلىكى ھوقۇق',
    },

    level2TabList: {
      interest: 'كۆرسەتمىسى ئىش بېجىرىش',
      object: 'نازارەتچىلىكى ئوبيېكت',
      rectify: 'نازارەتچىلىكى تۈزىتىش',
      project: 'ئاشكارىلاش تۈرنى',
      involvingRights: 'نازارەتچىلىك ھوقۇققا چېتىشلىق',
    },
    // 部门监督
    departGroup: {
      charge: "مەسئۇل ئاساسلىق",
      teamMembers: "ئەزالىرى بەنزە",
      postKeyPerson: "خادىملار نۇقتىلىق ئىش ئورنىدىكى خىزمەتچى",
      otherCadres: "كادىرلار باشقا رەھبىرىي",
      windowStaff: "خىزمەتچىسى كۆزنەك",
    },
    // 乡村监督
    RuralGroup: {
      leadCadre: 'كادىرلار رەھبىرىي',
      other: 'كادىرلار رەھبىرىي',
    },
    // 整改监督
    rectify: {
      letterType: 'تىپى خەت-چەك',
      state: 'ھالىتى بىر تەرەپ قىلىش',
      createTime: 'ۋاقتى خەت-چەك',
      name: 'جاۋاپ ئىدارىگە',
      backcontent: 'مەزمونى جاۋاپ',
      updateTime: 'ۋاقتى جاۋاپ',
    },
    // 项目公开
    project: {
      titleList: {
        planyear: 'يىلى پىلان',
        area: 'رايون',
        pno: 'نومۇرى تەستىق',
        porno: 'نومۇرى تۈر',
        company: 'ئورۇن خېرىدار بولغان',
        type: 'تىپى تۈر',
        starttime: 'ۋاقتى ئىش باشلاش',
        endtime: 'ۋاقتى قۇرۇلۇش پۈتۈش',
        price: 'باھاسى پۈتكەن قۇرۇلۇشنىڭ نەق چوت',
        remark: 'ئىزاھات',
      }
    },
    // 办事指南详情
    interestDetail: {
      sourceTitle: 'تەپسىلاتى ئىش بېجىرىش قوللانمىسىنىڭ',
      implementation: 'ئورۇن قوبۇل قىلغۇچى',
      myend: 'سۈرۈكى ۋەدە بېرىپ بېجىرىش',
      faend: 'ﺑﻪﻟﮕﯩﻠﻪﻧﮕﻪن ﻗﺎﻧﯘﻧﺪا',
      weekdays: 'خىزمەت كۈنى',     // 示例: 1个工作日
      ismoney: 'ئۆلچىمى ھەق ئېلىش',
      free: 'ھەقسىز',
      charge: 'ھەقلىق',
      accept: 'شەرتى بېجىرىش',
      process: 'تەرتىپى بېجىرىش',
    },
    // 涉权监督详情
    involvingRightsDetail: {
      sourceTitle: 'تەپسىلاتى ھوقۇققا چېتىشلىق نازارەت',
      personInfo: 'ئۇچۇرى ئۆز',
      name: 'فامىلىسى-ئىسىم',
      post: 'ئورنى خىزمەت',
      relativesInfo: 'ئۇچۇرى تۇغقانلار',
      relationship: 'مۇناسىۋىتى تۇغقانچىلىق',
      complaints: 'پىكرى ئەرز',
    },
    indexSuperDetail: {
      sourceTitle: 'تەپسىلاتى ھوقۇقنى نازارەت قىلىش',
    }
  },

  // 监督
  travel: {
    tabList: {
      policy: 'سىياسەت',
      interest: 'كۆرسەتمىسى ئىش بېجىرىش',
      huiqi: 'مەبلىغى كارخانىغا نەپ يەتكۈزۈش',
      enterprise: 'قىلىش نازارەت ئەرز',
    },
    // 政策
    policy: {
      policyTitle: 'سۈرۈشتۈرۈش سىياسەت',
    },
    // 监督投诉
    enterprise: {},
    // 惠企资金
    huiqi: {
      enterpriseName: 'نامى كارخانا',
      affirmType: 'تۈرى بىكىتىش',
      affirmUnit: 'ئورگان بىكىتكەن',
      rewardAmount: 'سوممىسى مۇكاپات',
      time: 'ۋاقتى',
    }
  },

  footer: {
    // 今日访问量
    todayVisits: 'كۆرۈلۈشى بۈگۈنكى',
    // 累计访问量
    cumulativeVisits: 'كۆرۈلۈش يىغىلما',
    // 监制单位标题
    producer: 'ئورگان نازارات قىلىنغان',
    // 联系地址标题
    contactAddress: 'ئادرىسى ئالاقىلىشىش',
    // 具体监制单位
    producerName: 'كومىتېتى ج ك پ شىنجاڭ ئۇيغۇر ئاپتونوم رايونلۇق ئىنتىزام تەكشۈرۈش',
    // 具体联系地址
    contactAddressName: 'نومۇر ئاپتونوم رايونلۇق ئىنتىزام تەكشۈرۈش كومىتېتى-10 شىنجاڭ ئۈرۈمچى شەھىرى شەرق شامىلى يولى',
  }
}